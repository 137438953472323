import AiAssistantApi from '../backend/AiAssistantApi';
import BackendObject from '../backend/BackendObject';
import { isEqual, cloneDeep } from 'lodash';


export default class FeatureRefining extends BackendObject {

  // Maps uuid to FeatureRefining object
  static uuidToFeatureRefining = new Map();

  // Get an FeatureRefining by its uuid
  static getByUuid(uuid) {
    if (FeatureRefining.uuidToFeatureRefining.has(uuid))
      return FeatureRefining.uuidToFeatureRefining.get(uuid);
    else 
      return null;
  }

  // Save a FeatureRefining by its uuid
  static async setByUuid(uuid, member) {
    FeatureRefining.uuidToFeatureRefining.set(uuid, member);
  }

  // Create a new local copy of FeatureRefining or update the existing local copy
  static updateLocal = (uuid, data) => {
    if (FeatureRefining.uuidToFeatureRefining.has(uuid)) {
      let member = FeatureRefining.uuidToFeatureRefining.get(uuid);
      member.uuid = uuid;
      member.data = data;
      return member;
    }
    else {
      let member = new FeatureRefining(uuid, data);
      FeatureRefining.setByUuid(uuid, member);
      return member;
    }
  }

  // Update local instances of FeatureRefining
  static updateLocalMany = (data) => {
    return data.map(item => {
        return FeatureRefining.updateLocal(item.uuid, item);
    });
  }

  // Load all members from backend
  static loadAll = async (feature) => {
    // console.log("FeatureRefining.loadAll()");
    // FeatureRefining.uuidToFeatureRefining = new Map();
    try {
      const api = new AiAssistantApi();
      let res = null;
      if (feature)
        res = await api.get("ai/feature-refinings", { feature: feature.uuid });
      else
        res = await api.get("ai/feature-refinings");

      this.updateLocalMany(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  // Load a single member; use the cached copy if available.
  static load = async (uuid, feature) => {

    let member = FeatureRefining.getByUuid(uuid);
    if (member)
      return member;

    const api = new AiAssistantApi();
    let res = await api.get(`ai/features/${feature.uuid}/refinings/${uuid}`);
    return FeatureRefining.updateLocal(res.data.uuid, res.data);
  };

  // Create a new feature refining inside a feature
  static createFromForm = async (parent, groupName, feature) => {
    const uuid = feature.uuid;
    const url = `ai/features/${uuid}/refinings`;
    const api = new AiAssistantApi();
    const res = await api.formCall("post", parent, groupName, url);
    const featureRefining = FeatureRefining.updateLocal(res.data.uuid, res.data);
    feature.notifyRegisteredListeners();
    return featureRefining;
  }

  constructor(uuid, data) {
    super();
    this.uuid = uuid;
    this.data = data;
  }

  // Update the data fields
  setData = (data) => {
    if (isEqual(data, this.data)) // lodash
        // No change
        return;

    this.data = cloneDeep(data);
    this.notifyRegisteredListeners();
  }

  // Save the feature refining
  updateFromForm = async (parent, groupName, feature) => {
    const uuid = feature.uuid;
    const refiningUuid = this.uuid;
    const url = `ai/features/${uuid}/refinings/${refiningUuid}`;
    const api = new AiAssistantApi();
    const res = await api.formCall("patch", parent, groupName, url);
    FeatureRefining.updateLocal(res.data.uuid, res.data);

    feature.notifyRegisteredListeners();
    this.notifyRegisteredListeners();
  }

  // Save the feature refining
  save = async (data, feature) => {
    const uuid = feature.uuid;
    const refiningUuid = this.uuid;
    const api = new AiAssistantApi();
    const url = `ai/features/${uuid}/refinings/${refiningUuid}`;
    const res = await api.patch(url, data);
    FeatureRefining.updateLocal(res.data.uuid, res.data)

    feature.notifyRegisteredListeners();
    this.notifyRegisteredListeners();
  }

  // Delete the feature refining
  delete = async (feature) => {
    const uuid = feature.uuid;
    const refiningUuid = this.uuid;
    const url = `ai/features/${uuid}/refinings/${refiningUuid}`;
    const api = new AiAssistantApi();
    await api.delete(url);
    this.deleted = true;
    FeatureRefining.uuidToFeatureRefining.delete(this.uuid);
    feature.notifyRegisteredListeners();
  }
}

