import React from 'react';
import UserAccountIcon from './UserAccountIcon';

import './UserAccountCard.scss';


/**
 * A card for a UserAccount object.
 * 
 * Props:
 *    userAccount      UserAccount object
 */
class UserAccountCard extends React.Component {
  componentDidMount = () => {
    this.props.userAccount.registerListener(this);    
  }

  componentWillUnmount = () => {
    this.props.userAccount.unregisterListener(this);    
  }

  backendObjectUpdated = (userAccount) => {
    this.setState({});
  }

  render = () => {
    const userAccount = this.props.userAccount;
    const data = userAccount.data;

    return (
      <div className="useraccount-card">
        <div className="avatar">
          <UserAccountIcon size="lg" border={false} userAccount={userAccount}/>
        </div>
        <div className="name">{data.full_name}</div>
        <div className="email">{data.email}</div>
      </div>
    );
  }
}

export default UserAccountCard;
