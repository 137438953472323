import React, { Component } from 'react';
import { Container } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

class ResizableSplitPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftWidth: 50,  // Percentuale della larghezza della colonna di sinistra
      isResizing: false,
      lastDownX: 0,
    };
  }

  // Inizio il ridimensionamento
  onMouseDown = (e) => {
    this.setState({ isResizing: true, lastDownX: e.clientX });
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  };

  // Aggiorno la larghezza delle colonne durante il ridimensionamento
  onMouseMove = (e) => {
    if (!this.state.isResizing) return;
    
    //const offsetRight = document.body.clientWidth - e.clientX;
    const newLeftWidth = ((e.clientX / document.body.clientWidth) * 100);

    // Imposto una larghezza minima per le colonne
    if (newLeftWidth > 10 && newLeftWidth < 90) {
      this.setState({ leftWidth: newLeftWidth });
    }
  };

  // Termino il ridimensionamento
  onMouseUp = () => {
    this.setState({ isResizing: false });
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  };

  render() {
    const { leftWidth } = this.state;
    const rightWidth = 100 - leftWidth;

    const containerStyle = {
      height: '100%', // Imposto l'altezza dell'intera finestra
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: 0,
      paddingRight: 0,
      userSelect: this.state.isResizing ? 'none' : 'auto',  // Evita selezioni durante il ridimensionamento
    };

    const leftPaneStyle = {
      width: `${leftWidth}%`,
      backgroundColor: '#fff',
      // padding: '10px',
    };

    const rightPaneStyle = {
      width: `${rightWidth}%`,
      backgroundColor: '#fff',
      // // padding: '10px',
    };

    const dividerStyle = {
      width: '5px',
      cursor: 'col-resize',
      backgroundColor: '#343a40',
      position: 'relative',
    };

    return (
      <Container fluid style={containerStyle}>
        <div style={leftPaneStyle}>
          {this.props.leftPane}
        </div>
        
        <div
          style={dividerStyle}
          onMouseDown={this.onMouseDown}
        />
        
        <div style={rightPaneStyle}>
          {this.props.rightPane}
        </div>
      </Container>
    );
  }
}

export default ResizableSplitPane;
