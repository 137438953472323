import React, { Component } from 'react';
import { FormGroup, Label, Input, FormText } from 'reactstrap';
import { withTranslation } from 'react-i18next';


class InputPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: '',
    };
  }

  handleInputChange = (e) => {
    this.setState({ inputText: e.target.value });
    if (this.props.onInputChange)
      this.props.onInputChange(e.target.value);
  }

  render() {
    const { inputText } = this.state;
    const t = this.props.t;

    return (
      <FormGroup style={{padding: "8px", display: "flex", flexDirection: "column"}}>
        <Label style={{flexGrow: 1}} for="inputText">{t("InputPane.field.label")}</Label>
        <Input
          style={{flexGrow: 1000}}
          type="textarea"
          name="text"
          id="inputText"
          value={inputText}
          onChange={this.handleInputChange}
          rows="30"
        />
        <FormText style={{flexGrow: 1}}>
          {t("InputPane.field.helpText")}
        </FormText>        
      </FormGroup>
    );
  }
}

export default withTranslation()(InputPane);
