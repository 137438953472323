import AiAssistantApi from '../backend/AiAssistantApi';
import BackendObject from '../backend/BackendObject';
import { isEqual, cloneDeep } from 'lodash';


export default class FeatureGroup extends BackendObject {

  // Maps uuid to FeatureGroup object
  static uuidToFeature = new Map();

  // Get an FeatureGroup by its uuid
  static getByUuid(uuid) {
    if (FeatureGroup.uuidToFeature.has(uuid))
      return FeatureGroup.uuidToFeature.get(uuid);
    else 
      return null;
  }

  // Save a FeatureGroup by its uuid
  static async setByUuid(uuid, member) {
    FeatureGroup.uuidToFeature.set(uuid, member);
  }

  // Create a new local copy of FeatureGroup or update the existing local copy
  static updateLocal = (uuid, data) => {
    if (FeatureGroup.uuidToFeature.has(uuid)) {
      let member = FeatureGroup.uuidToFeature.get(uuid);
      member.uuid = uuid;
      member.data = data;
      return member;
    }
    else {
      let member = new FeatureGroup(uuid, data);
      FeatureGroup.setByUuid(uuid, member);
      return member;
    }
  }

  // Update local instances of FeatureGroup
  static updateLocalMany = (data) => {
    data.map(item => {
        return FeatureGroup.updateLocal(item.uuid, item);
    });
  }

  // Load all members from backend
  static loadAll = async () => {
    // console.log("FeatureGroup.loadAll()");
    FeatureGroup.uuidToFeature = new Map();
    try {
      const api = new AiAssistantApi();
      const res = await api.get("ai/feature-groups");
      this.updateLocalMany(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  // Return all members (a copy of)
  static getAll = () => {
    let features = new Map();
    Array.from(FeatureGroup.uuidToFeature).forEach(([uuid, feature]) => features.set(uuid, feature));
    // console.log("FeatureGroup.getAll():", features);
    return features;
  }

  // Return a ref to all members.
  // Use with caution sice React render won't detect changes
  static getAllRef = () => {
    return FeatureGroup.uuidToFeature;
  }

  // Load a single member; use the cached copy if available.
  static load = async (uuid) => {

    let member = FeatureGroup.getByUuid(uuid);
    if (member)
      return member;

    const api = new AiAssistantApi();
    let res = await api.get(`ai/feature-groups/${uuid}`);
    // TODO: gestire i 404!
    return FeatureGroup.updateLocal(res.data.uuid, res.data);
  };

  constructor(uuid, data) {
    super();
    this.uuid = uuid;
    this.data = data;
  }

  // Update the data fields
  setData = (data) => {
    if (isEqual(data, this.data)) // lodash
        // No change
        return;

    this.data = cloneDeep(data);
    this.notifyRegisteredListeners();
  }

  // Save the feature group
  save = async (data) => {
    const api = new AiAssistantApi();
    let res = await api.patch(`ai/feature-groups/${this.uuid}`, data);
    FeatureGroup.updateLocal(res.data.uuid, res.data)
    return res;
  }

  // Delete the feature group
  delete = async (data) => {
    const api = new AiAssistantApi();
    await api.delete(`ai/feature-groups/${this.uuid}`, data);
    this.deleted = true;
    FeatureGroup.uuidToFeature.delete(this.uuid);
  }

  // Refresh contents of this FeatureGroup
  refresh = async () => {
    console.debug("FeatureGroup.refresh()");

    this.data = {};

    const api = new AiAssistantApi();
    try {
      const res = await api.get(`ai/feature-groups/${this.uuid}`);
      this.setData(res.data);
    } catch (err) {
      console.error(err);
    }
  }
}

