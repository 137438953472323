import AiAssistantApi from '../backend/AiAssistantApi';
import BackendObject from '../backend/BackendObject';
import UserAccountIcon from '../components/UserAccountIcon';
import { isEqual, cloneDeep } from 'lodash';


export default class UserAccount extends BackendObject {
  // Maps uuid to UserAccount object
  static uuidToUserAccount = new Map();

  // Get an UserAccount by its uuid
  static getByUuid(uuid) {
    if (UserAccount.uuidToUserAccount.has(uuid))
      return UserAccount.uuidToUserAccount.get(uuid);
    else 
      return null;
  }

  // Save a UserAccount by its uuid
  static async setByUuid(uuid, member) {
    UserAccount.uuidToUserAccount.set(uuid, member);
  }

  // Create a new local copy of UserAccount or update the existing local copy
  static updateLocal = (uuid, data) => {
    if (UserAccount.uuidToUserAccount.has(uuid)) {
      let member = UserAccount.uuidToUserAccount.get(uuid);
      member.uuid = uuid;
      member.data = data;
      return member;
    }
    else {
      let member = new UserAccount(uuid, data);
      UserAccount.setByUuid(uuid, member);
      return member;
    }
  }

  // Update local instances of UserAccount
  static updateLocalMany = (data) => {
    data.map(item => {
        return UserAccount.updateLocal(item.uuid, item);
    });
  }

  // Load all members from backend
  static loadAll = async () => {
    try {
      const api = new AiAssistantApi();
      const res = await api.get("accounts");
      this.updateLocalMany(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  // Return all members
  static getAll = () => {
    return UserAccount.uuidToUserAccount;
  }

  // Load a single member; use the cached copy if available.
  static load = async (uuid) => {

    let member = UserAccount.getByUuid(uuid);
    if (member)
      return member;

    const api = new AiAssistantApi();
    let res = await api.get(`accounts/${uuid}`);
    // TODO: gestire i 404!
    return UserAccount.updateLocal(res.data.uuid, res.data);
  };

  constructor(uuid, data) {
    super();
    this.uuid = uuid;
    this.data = data;
  }

  // Update the data fields
  setData = (data) => {
    if (isEqual(data, this.data)) // lodash
        // No change
        return;

    this.data = cloneDeep(data);
    this.notifyRegisteredListeners();
  }

  // Refresh contents of this UserAccount
  refresh = async () => {
    console.debug("UserAccount.refresh()");

    this.data = {};

    const api = new AiAssistantApi();
    try {
      const res = await api.get(`accounts/${this.uuid}`);
      this.setData(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Set user initials
   */
  setInitials = async (initials) => {
    const api = new AiAssistantApi();
    let res = await api.patch(`accounts/${this.uuid}`, { initials: initials });
    this.setData(res.data);
    return res;
  }

  // Update all user data
  update = async (data) => {
    const api = new AiAssistantApi();
    let res = await api.patch(
      `accounts/${this.uuid}`, 
      data
    );

    this.setData(res.data);
    return res;
  }

  // Change the avatar
  setAvatar = async (data) => {
    const api = new AiAssistantApi();
    api.setFormDataEncoding();

    let res = await api.patch("accounts/avatar", data);
    this.setData(res.data);
    return res;
  }

  // Return icon and title
  getIconAndTitle = (size) => {
    if (!size)
      size = "sm";
    return <><UserAccountIcon className="me-2" size={size} UserAccount={this} />{this.data.full_name}</>;
  }

  isPowerUser = () => {
    return this.data.is_power_user;
  }
}

