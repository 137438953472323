import AiAssistantApi from '../backend/AiAssistantApi';
import React from 'react';
import {FormField, FormSpinner, FormNonFieldErrors} from './forms';
import { MyDrawer, MyDrawerHeader, MyDrawerBody } from './MyDrawer';
import {
  FormGroup,
  Container,
} from "reactstrap";
import AccordionLink from './AccordionLink';
import SubmitButton from './SubmitButton';


/**
 * Editing form for the logged user account main data.
 * 
 * Props:
 *    userAccount      UserAccount object
 */
class UserAccountMainDataForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitStatus: false,
      mainData: AiAssistantApi.makeFormData({
        first_name: this.props.userAccount.data.first_name,
        last_name: this.props.userAccount.data.last_name,
      }),
    }
  }

  onSubmit = async () => {
    const api = new AiAssistantApi();
    this.setState({submitStatus: 'submitting'});
    let res = await api.formCall("patch", this, "mainData", `accounts/profile`);
    if (res) {
      this.setState({submitStatus: 'success'});
      this.props.userAccount.setData(res.data);
    }
    else
      this.setState({submitStatus: 'fail'});
    // this.toggleDrawer();
  }

  render = () => {
    return (
      <>
        <Container>
          <FormNonFieldErrors
            parent={this}
            groupName="mainData"
          />
          <FormField
            type="text"
            label="Username"
            name="username"
            value={this.props.userAccount.data.username}
            disabled={true}
          />
          <FormField
            type="text"
            label="E-mail"
            name="email"
            value={this.props.userAccount.data.email}
            disabled={true}
          />
          <FormField
            type="text"
            label="First Name"
            name="first_name"
            parent={this}
            groupName="mainData"
          />
          <FormField
            type="text"
            label="Last Name"
            name="last_name"
            parent={this}
            groupName="mainData"
          />
          <FormField
            type="text"
            label="Initials"
            name="initials"
            parent={this}
            groupName="mainData"
          />

          <SubmitButton
            submitStatus={this.state.submitStatus}
            disabled={this.state.mainData.submitting} 
            onClick={this.onSubmit}>
            Update
          </SubmitButton>
        </Container>
      </>
    );
  }
}

export default UserAccountMainDataForm;
