import React from 'react';
import './UserAccountIcon.scss';


/**
 * The icon for a UserAccount object.
 * 
 * Props:
 *    userAccount      UserAccount object
 *    size        xs|sm|md|lg|(none - defaults to "sm")
 *    selected    
 *    border      true (default)|false
 *    className   Additional CSS classes
 *    onClick     Callback on click
 */
class UserAccountIcon extends React.Component {
  componentDidMount = () => {
    this.props.userAccount.registerListener(this);    
  }

  componentWillUnmount = () => {
    this.props.userAccount.unregisterListener(this);    
  }

  backendObjectUpdated = (userAccount) => {
    this.setState({});
  }

  render = () => {
    const data = this.props.userAccount.data;
    return (
      <span 
        className={`useraccount-icon ${this.props.size ? this.props.size : "sm"} ${this.props.border===false ? "" : "bordered"} ${this.props.selected ? 'selected' : ''} ${this.props.className}`} 
        title={data.full_name}
        onClick={this.props.onClick}
      >
        {(data.avatar_url && (
        <img alt="" className="useraccount-avatar" src={data.avatar_url} />
        )) || (
        <span className="useraccount-initials">{data.initials}</span>
        )}
      </span>
      )
  }
}

export default UserAccountIcon;
