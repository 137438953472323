import React from 'react';

import './AccordionLink.scss';


/**
 * props:
 *  onClick
 *  href
 *  target
 *  chevron
 */
class AccordionLink extends React.Component {
  render = () => {
    return (
      <div className="accordion-item">
        {(this.props.onClick && (
          <div className="accordion-link" onClick={this.props.onClick}>
            {this.props.children}
            {this.props.chevron && (
              <i className="chevron bi bi-chevron-right"></i>
            )}
          </div>
        )) || (this.props.href && (
          <a className="accordion-link" href={this.props.href} target={this.props.target}>
            {this.props.children}
            {this.props.chevron && (
              <i className="chevron bi bi-chevron-right"></i>
            )}
          </a>
        ))}
      </div>
    );
  }
}

export default AccordionLink;
