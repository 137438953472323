import React from "react";
import Page from "../components/Page";
import ResizableSplitPane from '../components/ResizableSplitPane'
import InputPane from '../components/InputPane'
import AssistantFeatures from './AssistantFeatures';
import UserAccountIcon from './UserAccountIcon';
import UserAccountProfile from './UserAccountProfile';
import FeatureGroup from '../backend/FeatureGroup';
import { withRouter } from "./utils";
import {
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import i18n from 'i18next';
import settings from "../settings/settings.json";
import { globalApp } from "./App";
import { withTranslation } from 'react-i18next';


// The global MainPage singleton
export var globalMainPage = null;


/**
 * Main page
 */
class _MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserProfileOpen: false,
      loading: true,
      featureGroups: {},
      featureGroupsDropdownOpen: false,
      selectedFeatureGroup: null,
      language: 'it',
      inputText: '',
    };

    globalMainPage = this;
  }

  handleInputChange = (s) => {
    this.setState({inputText: s});
  }

  // Called when component mounts
  componentDidMount = async () => {
    await FeatureGroup.loadAll();
    const featureGroups = FeatureGroup.getAll();
    const userAccount = globalApp.getLoggedUserAccount();
    const isPowerUser = userAccount.isPowerUser();
    let selectedFeatureGroup = null;
    // console.log("featureGroups: ", featureGroups);
    if (isPowerUser)
      selectedFeatureGroup = "ALL";
    else if (featureGroups.size > 0) {
      selectedFeatureGroup = featureGroups.entries().next().value[1];
      // console.log("selectedFeatureGroup: ", selectedFeatureGroup);
    }
    this.setState({ 
      loading: false, 
      featureGroups: featureGroups, 
      selectedFeatureGroup: selectedFeatureGroup,
    });
    this.changeLanguage('it');
  }

  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    this.setState({ language: lng});
  }

  openUserProfile = () => {
    this.setState({ isUserProfileOpen: true });
  };

  closeUserProfile = () => {
    this.setState({ isUserProfileOpen: false });
  };

  selectFeatureGroup = (uuid) => {
    this.setState({ selectedFeatureGroup: uuid });
  };

  getInputText = () => {
    return this.inputPaneRef.current.getInputText();
  }

  toggleFeatureGroupsDropdown = () => {
    this.setState({ featureGroupsDropdownOpen: !this.state.featureGroupsDropdownOpen })
  }

  render = () => {
    // alert(globalApp);
    const userAccount = globalApp.getLoggedUserAccount();
    const isPowerUser = userAccount.isPowerUser();
    const t = this.props.t;

    return <Page 
      mainMenu={
        <Nav navbar className="me-auto mb-2 mb-lg-0 flex-grow-1">
          <Dropdown nav isOpen={this.state.featureGroupsDropdownOpen} toggle={this.toggleFeatureGroupsDropdown}>
            <DropdownToggle nav caret>
              {((this.state.selectedFeatureGroup === "ALL") && t("MainPage.nav.all"))
              || (this.state.selectedFeatureGroup && this.state.selectedFeatureGroup.data.title)
              || t("MainPage.nav.select")
              }
            </DropdownToggle>
            <DropdownMenu>
              {Array.from(this.state.featureGroups).map(([uuid, featureGroup]) => (
                <DropdownItem key={uuid} onClick={() => this.selectFeatureGroup(featureGroup) }>
                  <span className="me-2">{featureGroup.data.title}</span>
                  {this.state.selectedFeatureGroup && (this.state.selectedFeatureGroup.uuid === uuid) && (
                    <i className="bi bi-check"></i>
                  )}
                </DropdownItem>
              ))}
              {isPowerUser && (
              <>
                <DropdownItem divider />
                <DropdownItem key={false} onClick={() => this.selectFeatureGroup(false) }>
                  <span className="me-2">{t("MainPage.nav.all")}</span>
                  {(this.state.selectedFeatureGroup === "ALL") && (
                    <i className="bi bi-check"></i>
                  )}
                </DropdownItem>
              </>
              )}
            </DropdownMenu>
          </Dropdown>          
        </Nav>
      }
      userMenu={
        <>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <span className="nav-link" onClick={this.openUserProfile}>
                <UserAccountIcon size="md" border={false} userAccount={userAccount} />
              </span>
            </li>
            <UncontrolledDropdown nav inNavbar caret>
              <DropdownToggle nav>
                {this.state.language}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => this.changeLanguage("it")}>
                  Italiano {(this.state.language === 'it') && (<i className="bi bi-check"></i>)}
                </DropdownItem>
                <DropdownItem onClick={() => this.changeLanguage("en")}>
                  English {(this.state.language === 'en') && (<i className="bi bi-check"></i>)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ul>
  
          <UserAccountProfile 
            isOpen={this.state.isUserProfileOpen}
            userAccount={userAccount}
            onCloseClick={this.closeUserProfile} 
          />
        </>
      }
      main={
        <ResizableSplitPane
          className="body"
          leftPane={
            <div className="card">
              <div className="card-header text-center text-white bg-secondary">
                {t("MainPage.contentPane.title")}
              </div>
              <div className="card-body">
                <InputPane 
                  onInputChange={this.handleInputChange}
                />                  
              </div>
            </div>              
          }
          rightPane={
            ((this.state.selectedFeatureGroup !== null) &&
              <AssistantFeatures 
                featureGroup={this.state.selectedFeatureGroup}
                inputText={this.state.inputText}
              />
            )
          }
        />
      }
    />
  }
}

const MainPage = withRouter(_MainPage);

export default withTranslation()(MainPage);
