import React from 'react';
import {
  Button, Spinner
} from "reactstrap";

/**
 * Submit button
 * 
 * Props:
 *    disabled
 *    onClick
 */
class SubmitButton extends React.Component {
  render = () => {
    return (
      <>
        <Button 
          color="primary" 
          type={this.props.type || "button"}
          size={this.props.size}
          disabled={this.props.disabled || this.props.loading} 
          className={this.props.className}
          onClick={this.props.onClick}>
            {(this.props.loading && (
              <Spinner size="sm" className="me-1" />
            )) || (this.props.icon && (
              <i className={`${this.props.icon} me-1`} /> 
            ))}
            {this.props.children}
        </Button>
      </>
    );
  }
}

export default SubmitButton;
