// Ref: https://www.emgoto.com/react-inline-edit/
import React from 'react';

import './InlineEditor.scss';


/*
  Props:
    placeholder:
    value:

    onChanged             Fired when component is changed
    onEnterKeyPressed     Fired when the ENTER key is pressed.
    onEscKeyPressed       Fired when the ESC key is pressed.
*/
class InlineEditor extends React.Component {
  static allEditors = [];

  constructor(props) {
    super(props);
    this.state = { 
      text: this.props.value,
      changed: false,
     };
    this.textareaRef = React.createRef();
    this.timeout = null;
  }

  getText = () => {
    return this.state.text;
  }

  componentDidMount = () => {
    InlineEditor.allEditors.push(this);
//    window.addEventListener('resize', this.resize);
    this.resize();
    // console.log("Editor added (" + InlineEditor.allEditors.length + ")");
    if (this.props.focus)
      this.focus();
  }  

  // Called when component updates
  componentDidUpdate = (prevProps) => {
    if (this.props.value !== prevProps.value) {
      this.setState({
        text: this.props.value,
        changed: false,
      });
    }
  }  
  
  componentWillUnmount = () => { 
//    window.removeEventListener('resize', this.resize);
    InlineEditor.allEditors = InlineEditor.allEditors.filter(e => e !== this);
    // console.log("Editor removed (" + InlineEditor.allEditors.length + ")");
  }  

  // Call this to re-sync the state with the "value" prop. 
  // E.g when the external state changes.
  refresh = () => {
    this.setState({ 
      text: this.props.value,
     });
  }

  // Move the focus to the input element
  focus = () => {
    this.textareaRef.current.focus();
  };  

  // Force resize of all open inline editors
  static resizeAllEditors = () => {
    // console.log("Resizing all editors");
    InlineEditor.allEditors.forEach(editor => editor.resize());
  };  

  resize = () => {
    const node = this.textareaRef.current;
    node.style.height = '0';
    node.style.height = node.scrollHeight + 'px';
    // console.log("Editor resized");
  };  

  handleChange = (event) => {
    // console.debug("InlineEditor.handleChange()");
    this.setState({ text: event.target.value });
    if (this.props.onChanged) {
      this.props.onChanged(event.target.value);
    }    
  }

  // handleClick = (e) => {
  //   e.stopPropagation();
  // }

  handleInput = (event) => {
    this.resize();
  };  

  handleKeyDown = (event) => {
    // Ref: https://stackoverflow.com/a/571569490
    if(event.keyCode === 13 && this.props.onEnterKeyPressed) {  // ENTER
      event.preventDefault();
      const i = this.textareaRef.current.selectionStart;
      const text1 = this.state.text.substring(0, i);
      const text2 = this.state.text.substring(i);
      this.setState({ text: text1, changed: true });
      this.props.onEnterKeyPressed(text1, text2);
      return true;
    }
    else if(event.keyCode === 27 && this.props.onEscKeyPressed) {  // ESC
      event.preventDefault();
      this.props.onEscKeyPressed(this.state.text);
      return true;
    }
  }

  render = () => {
    // console.debug("[InlineEditor] render(): " + this.state.text);
    return (
        <textarea
          ref={this.textareaRef}
          rows={1}
          className={`inline-edit ${this.props.className ? this.props.className : ''}`}
          value={this.state.text}
          spellCheck="false"
          placeholder={ this.props.placeholder || "" }
          onChange={this.handleChange}
          // onClick={this.handleClick}
          onInput={this.handleInput}
          onKeyDown={this.handleKeyDown}
        />
      )
  }
}

// Force resize of all inline editors when window resizes.
window.addEventListener('resize', InlineEditor.resizeAllEditors);


export default InlineEditor;
