import React, { Component } from 'react';
import AiAssistantApi from '../backend/AiAssistantApi';
import Feature from '../backend/Feature';
import FeatureRefining from '../backend/FeatureRefining';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup,
} from 'reactstrap';
import { FormField, FormNonFieldErrors} from './forms';
import SubmitButton from './SubmitButton';


/**
 * props:
 *  type: "edit" or "create"
 *  featureRefining
 */
class FeatureRefiningEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      submitting: false,
    };

    if (this.props.type === "edit" && this.props.featureRefining) {
      this.state.featureRefiningData = AiAssistantApi.makeFormData({
        title: this.props.featureRefining.data.title,
        prompt: this.props.featureRefining.data.prompt,
      });
    }
    else if (this.props.type === "create")
      this.state.featureRefiningData = AiAssistantApi.makeFormData({
        title: "",
        prompt: "",
      });
  }

  // Funzione per aprire/chiudere la modale
  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }));
  }

  // Funzione per chiudere la modale
  closeModal = () => {
    this.setState({
      modalOpen: false
    });
  }

  // Funzione di gestione del pulsante "Save"
  handleSubmit = async () =>  {
    this.setState({submitting: true});

    try {
      if (this.props.type === 'edit') {
        const featureRefining = this.props.featureRefining;
        await featureRefining.updateFromForm(this, "featureRefiningData", this.props.feature);
      }
      else if (this.props.type === 'create') {
        await FeatureRefining.createFromForm(this, "featureRefiningData", this.props.feature);

        if (this.props.onChanged)
          await this.props.onChanged();
      }

      this.closeModal();
    }
    catch (err) {
      // pass
    }
    finally {
      this.setState({ submitting: false });
    }
  }

  // Funzione di gestione del pulsante "Delete"
  handleDelete = async () =>  {
    await this.props.featureRefining.delete(this.props.feature);

    if (this.props.onChanged)
      await this.props.onChanged();

    this.closeModal();
  }

  render = () => {
    return (
      <>
        {/* Bottone per aprire la modale */}
        <Button size="sm" color={this.props.color} className={this.props.className} disabled={this.props.disabled} onClick={this.toggleModal}>
            {this.props.children}
        </Button>

        {/* Modale */}
        <Modal size="lg" isOpen={this.state.modalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            {((this.props.type === "edit") && (
              <>
                Edit feature refining
              </>
            )) || ((this.props.type === "create") && (
              <>
                Add feature refining
              </>
            ))}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <FormNonFieldErrors
                  parent={this}
                  groupName="featureRefiningData"
                />
                <FormField
                  type="text"
                  label="Title"
                  name="title"
                  parent={this}
                  groupName="featureRefiningData"
                />
                <FormField
                  type="textarea"
                  label="Prompt"
                  name="prompt"
                  parent={this}
                  groupName="featureRefiningData"
                  rows={5}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <SubmitButton
              disabled={this.state.submitting} 
              onClick={this.handleSubmit}>
              {((this.props.type === "edit") && (
                <>
                  Update
                </>
              )) || ((this.props.type === "create") && (
                <>
                  Create
                </>
              ))}
            </SubmitButton>
            <Button color="secondary" onClick={this.closeModal} disabled={this.state.submitting}>
              Cancel
            </Button>
            {(this.props.type === "edit") && (
              <Button color="danger" onClick={this.handleDelete} disabled={this.state.submitting}>
                Delete
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default FeatureRefiningEditor;
