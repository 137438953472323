import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Form,
  } from "reactstrap";
import Page from "./Page";
import AiAssistantApi from '../backend/AiAssistantApi';
import {FormField, FormSpinner, FormNonFieldErrors} from './forms';
import SubmitButton from './SubmitButton';


/**
 * Page for user login.
 */
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      submitting: false,
      login: AiAssistantApi.makeFormData({
        username: "", 
        password: "",
      }),
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target;

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }

    const data = { ...this.state.data, [name]: value };
    this.setState({ data: data });
  };

  login = async (e) => {
    this.setState({ submitting: true });
    try {
      const api = new AiAssistantApi();
      await api.formCall("postNoToken", this, "login", "accounts/login", (res) => {
        this.props.onLoggedIn(res.data);
      });
    }
    catch (err) {
      // pass
    }
    finally {
      this.setState({ submitting: false });
    }
  }

  render = () => {
    // const data = this.state.data;
    return <Page 
      main={
        <main className="main main-defaultbg d-flex justify-content-center align-items-center">
          <Form className="login-form" method="post">
            <Card className="shadow">
              <CardHeader>
                <strong>Login to AI Assistant</strong>
                <div className="float-end">
                  <FormSpinner
                    parent={this}
                    groupName="login"
                  />
                </div>
              </CardHeader>
              <CardBody>
                <img className="logo" src="/logo192.png" alt="" width="80" height="80" />
                <hr />
                <p>Please log in:</p>
                <FormNonFieldErrors
                  parent={this}
                  groupName="login"
                />

                <FormField
                  type="text"
                  label="Username"
                  name="username"
                  placeholder="Enter username"
                  parent={this}
                  groupName="login"
                />
                <FormField
                  type="passwordEye"
                  label="Password"
                  name="password"
                  placeholder="Enter password"
                  parent={this}
                  groupName="login"
                />

                <p><small><a href="/">Forgot password?</a></small></p>
              </CardBody>  
              <CardFooter className="text-muted text-center">
                <SubmitButton 
                  icon="bi bi-lock" 
                  color="primary" 
                  loading={this.state.submitting} 
                  disabled={this.state.submitting} 
                  onClick={this.login}>
                    Login
                </SubmitButton>
              </CardFooter>
            </Card>
          </Form>
        </main>
      }
    />
  }  
}

export default LoginPage;
