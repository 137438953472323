import AiAssistantApi from '../backend/AiAssistantApi';
import React from 'react';
import { FormField, FormSpinner, FormNonFieldErrors } from './forms';
import {
  Container,
  Form,
  FormGroup
} from "reactstrap";
import SubmitButton from './SubmitButton';


/**
 * Editing form to change user password
 * 
 * Props:
 *    userAccount      UserAccount object
 */
class UserAccountChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitStatus: false,

      changePassword: AiAssistantApi.makeFormData({
        old_password: "",
        password: "",
        password2: "",
      }),
    }
  }

  onSubmit = async () => {
    const api = new AiAssistantApi();
    let res = api.formCall("put", this, "changePassword", "change-password");
    if (res)
      this.setState({submitStatus: 'success'});
    else
      this.setState({submitStatus: 'fail'});
  }

  render = () => {
    return (
      <>
        <Container>
          <Form>
            <FormNonFieldErrors
              parent={this}
              groupName="changePassword"
            />

            <FormField
              type="passwordEye"
              label="Old password"
              name="old_password"
              parent={this}
              groupName="changePassword"
              />
            <FormField
              type="passwordEye"
              label="New password"
              name="password"
              parent={this}
              groupName="changePassword"
              />
            <FormField
              type="passwordEye"
              label="Confirm new password"
              name="password2"
              parent={this}
              groupName="changePassword"
            />

            <SubmitButton
              submitStatus={this.state.submitStatus}
              disabled={this.state.changePassword.submitting} 
              onClick={this.onSubmit}>
              Update
            </SubmitButton>
          </Form>
        </Container>
      </>
    );
  }
}

export default UserAccountChangePasswordForm;
