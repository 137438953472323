import React, { Component } from "react";
import settings from "../settings/settings.json";
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem, NavLink } from 'reactstrap';


/**
 * Base page structure.
 */
class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render = () => { 
    const debugClass = settings.PRODUCTION ? '' : 'navbar-dev';

    return (
      <>
        <header className="head">
          <Navbar color="dark" dark expand="md">
            <NavbarBrand href="/">
              <img className="me-2" width="32" height="32" src={process.env.PUBLIC_URL + '/logo192.png'} alt="logo"></img>
              AI Assistant
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {this.props.mainMenu}
              </Nav>
            </Collapse>
            {this.props.customTopBar}
            {this.props.searchBox}
            {this.props.userMenu}    
          </Navbar>
        </header>
        <main className="main">
          {this.props.main}
        </main>
      </>
    );
  }
}

export default Page;