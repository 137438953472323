import React from 'react';
import {
  Button,
} from "reactstrap";
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor'; 

import './AvatarInput.scss';


/**
 * Avatar editor.
 * 
 * Props:
 *    imageUrl
 *    scale
 *    x
 *    y
 */
class AvatarInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: this.props.imageUrl,
      image: null,
      scale: this.props.scale,
      x: this.props.x,
      y: this.props.y,
    }
    this.avatarEditor = React.createRef();
  }

  getCroppedImage = async (name, imageType) => {
    if (!this.avatarEditor.current)
      return null;

    return new Promise((resolve, reject) => {
      try {
        const canvas = this.avatarEditor.current.getImage();
        canvas.toBlob(function(blob) {
          const file = new File([blob], name, { type: imageType });
          resolve(file);
        }, imageType);
      } catch (err) {
        reject();
      }
    });
  }

  getData = async () => {
    return {
      image: this.state.image,
      croppedImage: await this.getCroppedImage('avatar.png', 'image/png'),
      scale: this.state.scale,
      x: this.state.x,
      y: this.state.y,
    }
  }

  setImage = (image) => {
    this.setState({ 
      imageUrl: null,
      image: image,
      scale: 1.0,
      x: 0.5,
      y: 0.5,
    });
  }

  removeImage = () => {
    this.setState({ 
      imageUrl: null,
      image: null,
      scale: 1.0,
      x: 0.5,
      y: 0.5,
    });
  }

  resetImage = () => {
    this.setState({ 
      imageUrl: this.props.imageUrl,
      image: null,
      scale: this.props.scale,
      x: this.props.x,
      y: this.props.y,
    });
  }

  handleOnWheel = (e) => {
    const newScale = this.state.scale - e.deltaY/1400;
    if ((newScale >= 1) && (newScale < 4))
      this.setState({ scale: newScale });
  };  

  handleOnPositionChange = (position) => {
    this.setState({ x: position.x, y: position.y })
  };  

  render = () => {
    const w = 150;
    const b = 15;
    const s = (w + 2*b) + "px";

    // console.log("image", this.state.imageUrl ? this.state.imageUrl : this.state.image);

    return (
      <Dropzone
          onDrop={(dropped) => this.setImage(dropped[0])}
          noClick
          noKeyboard
      >
          {({ getRootProps, getInputProps, open }) => (
          <div className="avatar-input">
            <div className="avatar">
              <div style={{ width: s, height: s }} {...getRootProps()} onWheel={this.handleOnWheel}>
              {((this.state.image || this.state.imageUrl) && (
              <AvatarEditor
                  ref={this.avatarEditor}
                  image={this.state.imageUrl ? this.state.imageUrl : this.state.image}
                  width={w}
                  height={w}
                  border={b}
                  position={{ x: this.state.x, y: this.state.y }}
                  onPositionChange={this.handleOnPositionChange}
                  borderRadius={100}
                  color={[255, 255, 255, 0.8]} // RGBA
                  scale={this.state.scale}
                  rotate={0}
              />
              )) || (
                <div className="placeholder">
                  <i className="bi bi-upload"></i><br/>
                  Drag 'n' drop image, or click button to select.
                </div>
              )}
                <input {...getInputProps()} />
              </div>
            </div> 
            <div className="ms-3">
              <Button size="sm" color="primary" onClick={open}>Choose image...</Button><br/><br/>
              <Button size="sm" color="secondary" onClick={this.resetImage}>Revert</Button><br/><br/>
              <Button size="sm" color="secondary" onClick={this.removeImage}>Remove</Button>
            </div>
          </div>
          )}
      </Dropzone>
    );
  }
}

export default AvatarInput;
