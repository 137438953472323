import React from 'react';
import Drawer from 'react-modern-drawer'

import './MyDrawer.scss';


/**
 * props:
 *    onClose
 */
export class MyDrawerHeader extends React.Component {
  render = () => {
    return (
      <div className="card-header drawer-header">
        {this.props.children}
        <div className="bi bi-x close-button" title="Close" onClick={this.props.onClose}></div>
      </div>
    );
  }
}


export class MyDrawerBody extends React.Component {
  render = () => {
    return (
      <div className="card-body drawer-body">
        {this.props.children}
      </div>
    );
  }
}

/**
 * props:
 *    isOpen
 *    zIndex
 *    onClose
 */
export class MyDrawer extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
    }
  }
  
  render = () => {
    return (
      <Drawer
        open={this.props.isOpen}
        // onClose={toggleDrawer}
        direction='right'
        size={"500px"}
        className='card my-drawer'
        zIndex={this.props.zIndex}
      >
        {this.props.children}
      </Drawer>
    );
  }
}
