import React from 'react';
import {
  Container,
} from "reactstrap";
import AvatarInput from './AvatarInput';
import SubmitButton from './SubmitButton';


/**
 * Editing form for the logged user account main data.
 * 
 * Props:
 *    userAccount      UserAccount object
 */
class UserAccountAvatarForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitStatus: false,
    }
    this.avatarInputRef = React.createRef();
  }

  onSubmit = async () => {
    this.setState({submitStatus: 'submitting'});
    const ad = await this.avatarInputRef.current.getData();
    let data = {
      photo: ad.croppedImage,
      avatar_scale: ad.scale,
      avatar_x: ad.x,
      avatar_y: ad.y,
    }
    if (ad.image instanceof File) {
      data['source_photo'] = ad.image
    }

    let res = await this.props.userAccount.setAvatar(data);

    if (res)
      this.setState({submitStatus: 'success'});
    else
      this.setState({submitStatus: 'fail'});
  }

  render = () => {
    return (
      <>
        <Container>
          <AvatarInput 
            ref={this.avatarInputRef}
            imageUrl={this.props.userAccount.data.source_photo_url}
            scale={this.props.userAccount.data.avatar_scale}
            x={this.props.userAccount.data.avatar_x}
            y={this.props.userAccount.data.avatar_y}
          />
          <SubmitButton
            submitStatus={this.state.submitStatus}
            disabled={this.state.submitStatus == 'submitting'} 
            onClick={this.onSubmit}>
            Update
          </SubmitButton>
        </Container>
      </>
    );
  }
}

export default UserAccountAvatarForm;
