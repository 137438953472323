import AiAssistantApi from '../backend/AiAssistantApi';
import BackendObject from '../backend/BackendObject';
import FeatureRefining from '../backend/FeatureRefining';
import { isEqual, cloneDeep } from 'lodash';


export default class Feature extends BackendObject {

  // Output types
  static OUTPUT_TYPE_TEXT = 1;
  static OUTPUT_TYPE_IMAGE = 2;
  static OUTPUT_TYPE_CHOICES = {
    [Feature.OUTPUT_TYPE_TEXT]: "Text",
    [Feature.OUTPUT_TYPE_IMAGE]: "Image",
  };

  // Maps uuid to Feature object
  static uuidToFeature = new Map();

  // Get an Feature by its uuid
  static getByUuid(uuid) {
    if (Feature.uuidToFeature.has(uuid))
      return Feature.uuidToFeature.get(uuid);
    else 
      return null;
  }

  // Save a Feature by its uuid
  static async setByUuid(uuid, member) {
    Feature.uuidToFeature.set(uuid, member);
  }

  // Create a new local copy of Feature or update the existing local copy
  static updateLocal = (uuid, data) => {
    if (Feature.uuidToFeature.has(uuid)) {
      let member = Feature.uuidToFeature.get(uuid);
      member.uuid = uuid;
      member.data = data;
      return member;
    }
    else {
      let member = new Feature(uuid, data);
      Feature.setByUuid(uuid, member);
      return member;
    }
  }

  // Update local instances of Feature
  static updateLocalMany = (data) => {
    data.map(item => {
        return Feature.updateLocal(item.uuid, item);
    });
  }

  // Load all members from backend
  static loadAll = async (featureGroup) => {
    // console.log("Feature.loadAll()");
    Feature.uuidToFeature = new Map();
    try {
      const api = new AiAssistantApi();
      let res = null;
      if (featureGroup)
        res = await api.get("ai/features", { feature_group: featureGroup.uuid });
      else
        res = await api.get("ai/features");

      this.updateLocalMany(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  // Return all members (a copy of)
  static getAll = () => {
    let features = new Map();
    Array.from(Feature.uuidToFeature).forEach(([uuid, feature]) => features.set(uuid, feature));
    // console.log("Feature.getAll():", features);
    return features;
  }

  // Return a ref to all members.
  // Use with caution sice React render won't detect changes
  static getAllRef = () => {
    return Feature.uuidToFeature;
  }

  // Load a single member; use the cached copy if available.
  static load = async (uuid) => {

    let member = Feature.getByUuid(uuid);
    if (member)
      return member;

    const api = new AiAssistantApi();
    let res = await api.get(`ai/features/${uuid}`);
    // TODO: gestire i 404!
    return Feature.updateLocal(res.data.uuid, res.data);
  };

  // Create a new feature
  static create = async (data) => {
    let url = `ai/features`;
    const api = new AiAssistantApi();
    let res = await api.post(url, data);
    const feature = Feature.updateLocal(res.data.uuid, res.data);
    feature.justCreated = true;
    return feature;
  }

  // Create a new feature from a form
  static createFromForm = async (parent, groupName) => {
    const url = `ai/features`;
    const api = new AiAssistantApi();
    const res = await api.formCall("post", parent, groupName, url);
    const feature = Feature.updateLocal(res.data.uuid, res.data);
    return feature;
  }

  constructor(uuid, data) {
    super();
    this.uuid = uuid;
    this.data = data;
  }

  // Update the data fields
  setData = (data) => {
    if (isEqual(data, this.data)) // lodash
        // No change
        return;

    this.data = cloneDeep(data);
    this.notifyRegisteredListeners();
  }

  // Update this feature from a form
  updateFromForm = async (parent, groupName) => {
    const uuid = this.uuid;
    const url = `ai/features/${uuid}`;
    const api = new AiAssistantApi();
    const res = await api.formCall("patch", parent, groupName, url);
    FeatureRefining.updateLocal(res.data.uuid, res.data);
    this.notifyRegisteredListeners();
  }

  // Save the feature
  save = async (data) => {
    const api = new AiAssistantApi();
    let res = await api.patch(`ai/features/${this.uuid}`, data);
    Feature.updateLocal(res.data.uuid, res.data)
    this.notifyRegisteredListeners();
  }

  // Delete the feature
  delete = async (data) => {
    const api = new AiAssistantApi();
    await api.delete(`ai/features/${this.uuid}`, data);
    this.deleted = true;
    Feature.uuidToFeature.delete(this.uuid);
    this.notifyRegisteredListeners();
  }

  // Refresh contents of this Feature
  refresh = async () => {
    console.debug("Feature.refresh()");

    this.data = {};

    const api = new AiAssistantApi();
    try {
      const res = await api.get(`ai/features/${this.uuid}`);
      this.setData(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  // Interroga il modello con questa query/genera testo
  ask = async (data) => {
    try {
      const api = new AiAssistantApi();
      const res = await api.post(`ai/features/${this.uuid}/ask`, data);
      // console.log(res);
      return res;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  // Interroga il modello con questa query/genera immagine
  generate_image = async (text) => {
    try {
      const api = new AiAssistantApi();
      const res = await api.post(`ai/features/${this.uuid}/generate_image`, {
          text: text,
      });
      // console.log(res);
      return res;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  get_refinings = async () => {
    try {
      const api = new AiAssistantApi();
      const res = await api.get(`ai/features/${this.uuid}/refinings`);
      // console.log("Refinings:", res.data);
      return FeatureRefining.updateLocalMany(res.data);
    } catch (err) {
      console.error(err);
      return false;
    }
  }
}

