import React from 'react';
import {
  Alert,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  InputGroup,
  Button,
  Spinner,
  FormText
} from "reactstrap";


/**
 * A field in a form, with error management.
 * 
 * Props:
 *    type      "password" "text" "passwordEye" "switch"
 *    label     "Old password"
 *    name      "old_password"
 *    placeholder
 *    parent    
 *    groupName  "changePassword"
 *    rightButtonContent
 *    rightButtonType
 *    onRightButtonClick
 *    help
 */
export class FormField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      passwordType: 'password',
    };
  }

  handleOnChange = (e) => {
    let value = e.target.value;

    if (e.target.type === "checkbox")
      value = e.target.checked;

    const data = { ...this.props.parent.state[this.props.groupName].data, [this.props.name]: value };
    this.props.parent.setState({ 
      [this.props.groupName]: {
        data: data,
        errors: [],
      }
    });
  };

  handleOnChangeMultiple = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    let values = this.props.parent.state[this.props.groupName].data[this.props.name];
    if (values.includes(value)) {
      if (!checked) {
        values = values.filter((val) => (val !== value));
      }
    }
    else {
      if (checked)
        values.push(value);
    }

    const data = { ...this.props.parent.state[this.props.groupName].data, [this.props.name]: values };
    this.props.parent.setState({ 
      [this.props.groupName]: {
        data: data,
        errors: [],
      }
    });
  };

  showPassword = (e) => {
    console.log('show');
    this.setState({ passwordType: 'text' });
  };

  hidePassword = (e) => {
    console.log('hide');
    this.setState({ passwordType: 'password' });
  };

  render = () => {
    let value="", error = false;

    if (this.props.groupName) {
      value = this.props.parent.state[this.props.groupName].data[this.props.name];
      const errors = this.props.parent.state[this.props.groupName].errors;
      error = this.props.name in errors ? errors[this.props.name] : false;
    }
    else {
      value = this.props.value;
    }

    // console.log("value: ", value);

    const choices = this.props.choices || {};

    if (this.props.type === 'switch-multiple') {
      return (
        <FormGroup>
          <Label size="sm">{this.props.label}</Label>
          {Array.from(choices).map(([val, label]) => (
            <FormGroup switch key={val} className="mb-2">
              <Input 
                type="switch"
                id={`id_${val}`}
                value={val}
                checked={value.includes(val)}
                name={this.props.name}
                onChange={this.handleOnChangeMultiple}
              />
              <Label for={`id_${val}`} check>{label}</Label>
            </FormGroup>))
          }
          {this.props.help && (
          <FormText>{this.props.help}</FormText>
          )}
        </FormGroup>
      );
    }
    else
      return (
        <FormGroup>
          <Label size="sm" for={`${this.props.name}_id`}>{this.props.label}</Label>
          <InputGroup size="sm">
            <Input
              type={(this.props.type === 'passwordEye') ? this.state.passwordType : this.props.type}
              id={`${this.props.name}_id`}
              name={this.props.name}
              value={value} 
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              invalid={!!error} 
              onChange={this.handleOnChange}
              rows={this.props.rows}
            >
              {Object.entries(choices).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
              ))}
            </Input>
            {((this.props.type === 'passwordEye') && (
              <Button
                type={this.props.rightButtonType ? this.props.rightButtonType : "button"}
                onMouseDown={this.showPassword}
                onMouseUp={this.hidePassword}
                onMouseOut={this.hidePassword}>
                <i className="bi bi-eye-slash"></i>
              </Button>
            )) || ((this.props.rightButtonContent) && (
              <Button
                type={this.props.rightButtonType ? this.props.rightButtonType : "button"}
                onClick={this.props.onRightButtonClick}>
                {this.props.rightButtonContent}
              </Button>
            ))}
            {error && (
            <FormFeedback>{error}</FormFeedback>
            )}
          </InputGroup>
          {this.props.help && (
          <FormText>{this.props.help}</FormText>
          )}
        </FormGroup>            
      );
  }
}


/**
 * A form spinner.
 * 
 * Props:
 *    parent    
 *    groupName  "changePassword"
 */
export class FormSpinner extends React.Component {
  render = () => {
    const submitting = this.props.parent.state[this.props.groupName].submitting;

    return submitting && (
      <Spinner size="sm" />
    )
  }
}


/**
 * A form global error.
 * 
 * Props:
 *    parent    
 *    groupName  "changePassword"
 */
export class FormNonFieldErrors extends React.Component {
  render = () => {
    const errors = this.props.parent.state[this.props.groupName].errors;
    const nonFieldErrors = 'non_field_errors' in errors ? errors.non_field_errors : false; 
    return nonFieldErrors && (
      <Alert color="warning">{nonFieldErrors}</Alert>
    )
  }
}





export default FormField;
