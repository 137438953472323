import React from 'react';
import { globalApp } from './App';
import UserAccountCard from './UserAccountCard';
import UserAccountMainDataForm from './UserAccountMainDataForm';
import UserAccountAvatarForm from './UserAccountAvatarForm';
import UserAccountChangePasswordForm from './UserAccountChangePasswordForm';
import { MyDrawer, MyDrawerHeader, MyDrawerBody } from './MyDrawer';
import { Accordion } from 'reactstrap';
import AccordionLink from './AccordionLink';

import './UserAccountProfile.scss';


/**
 * Sub-drawer in user profile editor
 * 
 * Props:
 *    title
 */
class UserAccountProfileDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    }
  }

  toggleDrawer = async () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render = () => {
    return (
      <>
        <AccordionLink onClick={this.toggleDrawer} chevron={true}>
          <i className="bi bi-gear me-2"></i>
          {this.props.title}
        </AccordionLink>

        <MyDrawer
          isOpen={this.state.isOpen}
          zIndex={200}
          onClose={this.toggleDrawer}
        >
          <MyDrawerHeader onClose={this.toggleDrawer}>
            {this.props.title}
          </MyDrawerHeader>
          <MyDrawerBody>
            {this.props.children}
          </MyDrawerBody>
        </MyDrawer>
      </>
    );
  }
}


/**
 * User profile editing. as a drawer.
 * 
 * Props:
 *    userAccount      UserAccount object
 *    isOpen      
 *    onCloseClick
 */
class UserAccountProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openItemId: String(0),  // Tiene traccia dell'elemento Accordion attualmente aperto
    }
  }

  toggleItem = (itemId) => {
    // Se l'elemento è già aperto, lo chiude; altrimenti lo apre
    this.setState((prevState) => ({
      openItemId: prevState.openItemId === itemId ? null : itemId,
    }));
  };

  render = () => {

    return (
      <MyDrawer isOpen={this.props.isOpen}>
        <MyDrawerHeader onClose={this.props.onCloseClick}>
          <UserAccountCard userAccount={this.props.userAccount}/>
        </MyDrawerHeader>

        <MyDrawerBody>
          <Accordion open={this.state.openItemId} toggle={this.toggleItem}>

            <UserAccountProfileDrawer title="Main data">
              <UserAccountMainDataForm userAccount={this.props.userAccount} />
            </UserAccountProfileDrawer>

            <UserAccountProfileDrawer title="Avatar">
              <UserAccountAvatarForm userAccount={this.props.userAccount} />
            </UserAccountProfileDrawer>

            <UserAccountProfileDrawer title="Change Password">
              <UserAccountChangePasswordForm userAccount={this.props.userAccount} />
            </UserAccountProfileDrawer>

          {globalApp.state.userAccount.data.can_access_admin && (
            <AccordionLink href="/backend/admin/" target="aiassistant-admin">
              <i className="bi bi-gear me-2"></i>
              Admin site
            </AccordionLink>
          )}

            <AccordionLink onClick={globalApp.logoutUser}>
              <i className="bi bi-box-arrow-right me-2"></i>
              Logout
            </AccordionLink>

          </Accordion>

        </MyDrawerBody>
      </MyDrawer>
    );
  }
}

export default UserAccountProfile;
