import React, { Component } from 'react';
import { Button, ButtonGroup, Card, CardHeader, CardBody, Badge, CardFooter } from 'reactstrap';
import Feature from '../backend/Feature';
import FeatureGroup from '../backend/FeatureGroup';
import FeatureEditor from './FeatureEditor';
import FeatureRefiningEditor from './FeatureRefiningEditor';
import ReactMarkdown from 'react-markdown';
import SubmitButton from './SubmitButton';
import InlineEditor from './InlineEditor';
// import remarkGfm from 'remark-gfm'; 
import { ToastContainer, toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import { globalApp } from "./App";



/** Feature output, with copy function. */
class AssistantFeatureOutput extends Component {
  constructor(props) {
    super(props);
    this.outputRef = React.createRef();
  }

  handleCopyClick = async () => {
    const html = this.outputRef.current.innerHTML;
    // console.debug("Text to copy: ", html);
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(html); // Scrivi il testo negli appunti
      toast('Testo copiato negli appunti!', {
        position: "top-right",
        autoClose: 3000, // Durata in millisecondi prima di auto-scomparire
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  render = () => {
    return (
      <>
      <div className="assistant-feature-output">
        {navigator.clipboard && (
        <div className="copy" onClick={this.handleCopyClick}>
          <i className="bi bi-clipboard"></i>
        </div>
        )}
        <div ref={this.outputRef}>
          <ReactMarkdown>
            {this.props.children}
          </ReactMarkdown>
        </div>
      </div>
      <ToastContainer />
      </>
    );
  }
};



class FeatureRefiningButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      loading: false,
    };
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  handleClick = async () => {
    this.setState({ loading: true });
    await this.props.assistantFeature.runRefining(this.props.featureRefining);
    this.setState({ loading: false });
  }

  render = () => {
    const loading = this.props.disabled;

    return (
      <ButtonGroup className={this.props.className}>
        <SubmitButton 
          icon="bi bi-play" 
          size={this.props.size} 
          color="primary" 
          loading={this.state.loading} 
          disabled={this.props.disabled || this.state.loading} 
          onClick={this.handleClick}
        >
          {this.props.children}
        </SubmitButton>

        <FeatureRefiningEditor 
          featureRefining={this.props.featureRefining}
          feature={this.props.feature}
          disabled={this.props.disabled || this.state.loading} 
          type="edit" 
          color="primary">
          <i className="bi bi-pen"></i>
        </FeatureRefiningEditor>
      </ButtonGroup>
    );
  }
};


class _AssistantFeatureRefinings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custom: false,
      customLoading: false,
      featureRefinings: [],
      customPrompt: "",
    };
  }

  componentDidMount = async () => {
    this.props.feature.registerListener(this);
    const featureRefinings = await this.props.feature.get_refinings();
    this.setState({featureRefinings: featureRefinings});
  }

  componentWillUnmount = () => {
    this.props.feature.unregisterListener(this);
  }

  handleCustomPromptChange = (s) => {
    this.setState({customPrompt: s});
  }

  backendObjectUpdated = async (feature) => {
    const featureRefinings = await this.props.feature.get_refinings();
    this.setState({ featureRefinings: featureRefinings });
  }

  toggleCustom = () => {
    this.setState({ custom: !this.state.custom });
  }

  handleCustomClick = async () => {
    this.setState({ customLoading: true });
    const customPrompt = this.state.customPrompt;
    console.log("customPrompt", customPrompt);
    await this.props.assistantFeature.runRefining(null, customPrompt);
    this.setState({ customLoading: false, custom: false });
  }

  render = () => {
    const loading = this.props.disabled;
    const feature = this.props.feature;
    const featureRefinings = this.state.featureRefinings;
    const userAccount = globalApp.getLoggedUserAccount();
    const isPowerUser = userAccount.isPowerUser();
    const t = this.props.t;

    return (
      <>
        <div className="mb-1" style={{fontVariant: "small-caps", fontSize: "90%", color: "#888"}}>
        {t("AssistantFeatureRefinings.title")}
        </div>
        <div className="d-flex justify-content-between">
          {(this.state.custom && (
            <>
              <InlineEditor 
                focus={true}
                onChanged={this.handleCustomPromptChange}
              />
              <span>
                <SubmitButton 
                  icon="bi bi-play" 
                  size="sm" 
                  color="primary" 
                  loading={this.state.customLoading} 
                  disabled={!this.state.customPrompt || this.props.disabled}
                  onClick={this.handleCustomClick}
                >
                  {t("AssistantFeatureRefinings.buttons.go")}
                </SubmitButton>
                <Button 
                  size="sm" 
                  className="ms-2" 
                  color="info"
                  onClick={this.toggleCustom}
                  disabled={this.props.disabled}>
                  {t("AssistantFeatureRefinings.buttons.cancel")}
                </Button>
              </span> 
            </>
          )) || (
            <>
              <span>
              {featureRefinings.map((featureRefining, index) => {
                return (
                  <FeatureRefiningButton 
                    key={index} 
                    className="me-2" 
                    size="sm"
                    type="edit" 
                    assistantFeature={this.props.assistantFeature}
                    featureRefining={featureRefining}
                    feature={feature}
                    loading={this.props.loading}
                  >
                    {featureRefining.data.title}
                  </FeatureRefiningButton>
                  );
              })}
            </span>
            <span>
              <Button 
                size="sm" 
                className="ms-2" 
                color="info"
                onClick={this.toggleCustom}
                disabled={this.props.disabled}>
                {t("AssistantFeatureRefinings.buttons.customPrompt")}
              </Button>
              {isPowerUser && (
              <FeatureRefiningEditor 
                className="ms-2" 
                type="create"
                feature={feature}
                disabled={this.props.disabled}
                color="warning">
                <i className="bi bi-plus-circle me-2"></i> {t("AssistantFeatureRefinings.buttons.new")}
              </FeatureRefiningEditor>
              )}
            </span> 
          </>
        )}           
        </div>
      </>
    );
  }
};


const AssistantFeatureRefinings =  withTranslation()(_AssistantFeatureRefinings);



class AssistantFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      disabled: false,
      text: '',
      responseError: false, 
      responseText: '',
      responseImageUrl: '',
      refiningOutcomes: [],
    };
  }

  componentDidMount = async () => {
    // this.props.feature.registerListener(this);
  }

  componentWillUnmount = () => {
    // this.props.feature.unregisterListener(this);
  }

  handleClear = () => {
    this.setState({ text: '', responseText: '', responseImageUrl: '', refiningOutcomes: [] });
  }

  runRefining = async (featureRefining, customPrompt) => {
    const data = {
      text: this.state.text,
      response_text: this.state.responseText,
      refinings: this.state.refiningOutcomes.map(r => ({ 
        'prompt': r.prompt, 
        'response_text': r.responseText,
      })),
      feature_refining: featureRefining ? featureRefining.uuid : null,
      custom_prompt: customPrompt,
    }
    console.log("Refining data:", data);
    this.setState({ disabled: true });
    try {
      const res = await this.props.feature.ask(data);
      // console.log(res);
      const refiningOutcomes = [ ...this.state.refiningOutcomes, { 
        featureRefining: featureRefining,
        title: featureRefining ? featureRefining.data.title : customPrompt,
        prompt: featureRefining ? featureRefining.data.prompt : customPrompt,
        responseText: res.data.choices[0].message.content,
      }];
      this.setState({ refiningOutcomes: refiningOutcomes, disabled: false });
    }
    catch (err) {
      this.setState({ disabled: false });
      throw err;
    }
  }

  handleClick = async (e) => {
    e.preventDefault();

    let inputText = this.props.inputText;
    if (!inputText.trim()) {
      toast(this.props.t('AssistantFeature.run.missingText'), {
        position: "top-right",
        autoClose: 3000, // Durata in millisecondi prima di auto-scomparire
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    this.setState({ 
      loading: true, 
      text: inputText,
      responseError: false, 
      responseText: '', 
      responseImageUrl: '' 
    });

    try {
      if (this.props.feature.data.output_type === Feature.OUTPUT_TYPE_TEXT) {
        const data = {
          text: inputText,
        }
        let res = await this.props.feature.ask(data);
        // console.log(res);
        let responseText = res.data.choices[0].message.content;
        this.setState({ responseText: responseText, loading: false });
      }
      else if (this.props.feature.data.output_type === Feature.OUTPUT_TYPE_IMAGE) {
        let res = await this.props.feature.generate_image(inputText);
        // console.log(res);
        let responseImageUrl = res.data.image_url;
        let responseText = res.data.prompt;
        this.setState({ responseImageUrl: responseImageUrl, responseText: responseText, loading: false });
      }

    } catch (error) {
      console.error('Errore nella richiesta:', error);
      this.setState({ responseError: true, loading: false });
    }
  }

  render() {
    const responseText = this.state.responseText;
    const responseImageUrl = this.state.responseImageUrl;
    const loading = this.state.loading;
    const feature = this.props.feature;
    const userAccount = globalApp.getLoggedUserAccount();
    const isPowerUser = userAccount.isPowerUser();
    const t = this.props.t;
    const hasText = this.props.inputText.trim() != '';

    // console.log("Feature refinings:", featureRefinings);

    return (
      <>
        <Card className="mb-3">
          <CardHeader className={"d-flex justify-content-between" + (feature.data.is_experimental ? " text-white bg-dark" : "")}>
            <span className="flex-grow-1">
              <div>
                {feature.data.title}
                {feature.data.is_experimental && (
                  <i className="bi bi-cone-striped ms-2"></i>
                )}
              </div>
              <div>
              {isPowerUser && feature.data.feature_groups.map((uuid, index) => {
                const featureGroup = FeatureGroup.getByUuid(uuid);
                return (
                  <Badge className="me-2" key={uuid}>{featureGroup.data.short_name}</Badge>
                );
              })}
              </div>
            </span>
            <span className="d-flex align-items-start">
              <SubmitButton 
                icon="bi bi-play" 
                size="sm" 
                color="primary" 
                loading={loading} 
                disabled={!hasText || this.state.disabled || !!(responseText || responseImageUrl)} 
                onClick={this.handleClick}
              >
                {t("AssistantFeature.buttons.go")}
              </SubmitButton>
              &nbsp;
              <Button 
                className="ms-1" 
                size="sm" 
                color="primary" 
                disabled={this.state.disabled || loading || !responseText && !responseImageUrl} 
                onClick={this.handleClear}
              >
                <i className="bi bi-x" /> {t("AssistantFeature.buttons.clear")}
              </Button>
              &nbsp;
              {isPowerUser && (
              <FeatureEditor
                className="ms-1" 
                type="edit" 
                feature={feature} 
                onChanged={this.props.onChanged} 
                disabled={this.state.disabled || loading}
              />
              )}
            </span>
          </CardHeader>
          {this.state.responseError && (
          <CardBody>
            <div style={{color: "red"}}>{t("AssistantFeature.errorMsg")}</div>
          </CardBody>
          )}
          {responseImageUrl && (
          <CardBody>
            <img src={responseImageUrl} alt="AI Generated" />
          </CardBody>
          )}
          {responseText && (<>
          <CardBody>
            <AssistantFeatureOutput>
              {responseText}
            </AssistantFeatureOutput>
          </CardBody>
        {this.state.refiningOutcomes.map((item, index) => (
        <div key={index}>
          <CardHeader style={{borderTop: "1px solid silver"}}>
            {item.title}
          </CardHeader>
          <CardBody>
            <AssistantFeatureOutput>
              {item.responseText}
            </AssistantFeatureOutput>
          </CardBody>
        </div>
        ))}
          <CardFooter>
            <AssistantFeatureRefinings
              feature={feature}
              assistantFeature={this}
              disabled={this.state.disabled}
            />
            </CardFooter>
          </>)}
        </Card>
        <ToastContainer />
      </>
    );
  }
}

export default withTranslation()(AssistantFeature);
