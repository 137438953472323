import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup,
  Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, Input, Label
 } from 'reactstrap';
import { FormField, FormNonFieldErrors} from './forms';
import { withTranslation } from 'react-i18next';

import AiAssistantApi from '../backend/AiAssistantApi';
import Feature from '../backend/Feature';
import FeatureGroup from '../backend/FeatureGroup';
import SubmitButton from './SubmitButton';
import classnames from 'classnames'; // Per la gestione delle classi attive


/**
 * props:
 *  type: "edit" or "create"
 *  feature
 */
class FeatureEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      activeTab: 'main',
      submitting: false,
    };

    if (this.props.type === "edit") {
      if (!this.props.feature)
        throw new Error("Missing: feature")

      this.state.featureData = AiAssistantApi.makeFormData({
        title: this.props.feature.data.title,
        output_type: this.props.feature.data.output_type,
        context: this.props.feature.data.context,
        prompt: this.props.feature.data.prompt,
        temperature: this.props.feature.data.temperature,
        max_tokens: this.props.feature.data.max_tokens,
        feature_groups: this.props.feature.data.feature_groups,
      });
    }
    else if (this.props.type === "create")
      this.state.featureData = AiAssistantApi.makeFormData({
        title: "",
        output_type: Feature.OUTPUT_TYPE_TEXT,
        context: "",
        prompt: "Ecco l'articolo: %TEXT%",
        temperature: 0.7,
        max_tokens: 1000,
        feature_groups: [],
      });
  }

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  }

  // Funzione per aprire/chiudere la modale
  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }));
  }

  // Funzione per chiudere la modale
  closeModal = () => {
    this.setState({
      modalOpen: false
    });
  }

  // Funzione di gestione del pulsante "Save"
  handleSubmit = async () =>  {
    this.setState({ submitting: true });

    try {
      if (this.props.type === 'edit') {
        const feature = this.props.feature;
        await feature.updateFromForm(this, "featureData");
      }
      else if (this.props.type === 'create') {
        await Feature.createFromForm(this, "featureData");
  
        if (this.props.onChanged)
          await this.props.onChanged();
      }

      this.closeModal();
    }
    catch (err) {
      // pass
    }
    finally {
      this.setState({ submitting: false });
    }
  }

  // Funzione di gestione del pulsante "Save"
  handleDelete = async () =>  {
    await this.props.feature.delete();

    if (this.props.onChanged)
      await this.props.onChanged();

    this.closeModal();
  }

  // Funzione di gestione del pulsante "Save"
  handleSave = () =>  {
    this.closeModal();  // Chiudi la modale dopo il salvataggio
  }

  render = () => {

    const featureGroups = FeatureGroup.getAll();
    const featureGroupChoices = new Map();
    Array.from(featureGroups).forEach(([uuid, featureGroup]) => featureGroupChoices.set(uuid, featureGroup.data.title));
    // console.log("featureGroupChoices: ", featureGroupChoices);
    const t = this.props.t;

    return (
      <div>
        {/* Bottone per aprire la modale */}
        <Button className={this.props.className} size="sm" color="warning" disabled={this.props.disabled} onClick={this.toggleModal}>
          {((this.props.type === "edit") && (
            <>
              <i className="bi bi-pen me-2"></i> {t("FeatureEditor.buttons.edit")}
            </>
          )) || ((this.props.type === "create") && (
            <>
              <i className="bi bi-plus-circle me-2"></i> {t("FeatureEditor.buttons.create")}
            </>
          ))}
        </Button>

        {/* Modale */}
        <Modal size="lg" isOpen={this.state.modalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            {((this.props.type === "edit") && (
              <>
                {t("FeatureEditor.modal.title.edit")}
              </>
            )) || ((this.props.type === "create") && (
              <>
                {t("FeatureEditor.modal.title.new")}
              </>
            ))}
          </ModalHeader>
          <ModalBody>
            <Form>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'main' })}
                    onClick={() => { this.setActiveTab('main'); }}
                  >
                    {t("FeatureEditor.tab.Main")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === 'availability' })}
                    onClick={() => { this.setActiveTab('availability'); }}
                  >
                    {t("FeatureEditor.tab.Availability")}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab} className="pt-2">
                <TabPane tabId="main">
                  <FormGroup>
                    <FormNonFieldErrors
                      parent={this}
                      groupName="featureData"
                    />
                    <FormField
                      name="title"
                      type="text"
                      parent={this}
                      groupName="featureData"
                      label={t("FeatureEditor.field.title.label")}
                      help={t("FeatureEditor.field.title.help")}
                    />
                    <FormField
                      name="output_type"
                      type="select"
                      choices={Feature.OUTPUT_TYPE_CHOICES}
                      parent={this}
                      groupName="featureData"
                      label={t("FeatureEditor.field.output_type.label")}
                      help={t("FeatureEditor.field.output_type.help")}
                    />
                    <FormField
                      name="context"
                      type="textarea"
                      parent={this}
                      groupName="featureData"
                      rows={14}
                      label={t("FeatureEditor.field.context.label")}
                      help={t("FeatureEditor.field.context.help")}
                    />
                    <FormField
                      name="prompt"
                      type="textarea"
                      parent={this}
                      groupName="featureData"
                      rows={5}
                      label={t("FeatureEditor.field.prompt.label")}
                      help={t("FeatureEditor.field.prompt.help")}
                    />
                    <FormField
                      name="temperature"
                      type="text"
                      parent={this}
                      groupName="featureData"
                      label={t("FeatureEditor.field.temperature.label")}
                      help={t("FeatureEditor.field.temperature.help")}
                    />
                    <FormField
                      name="max_tokens"
                      type="text"
                      parent={this}
                      groupName="featureData"
                      label={t("FeatureEditor.field.max_tokens.label")}
                      help={t("FeatureEditor.field.max_tokens.help")}
                    />
                  </FormGroup>
                </TabPane>
                <TabPane tabId="availability" className="pt-2">
                  <FormField
                    name="feature_groups"
                    type="switch-multiple"
                    choices={featureGroupChoices}
                    parent={this}
                    groupName="featureData"
                    label={t("FeatureEditor.field.feature_groups.label")}
                    help={t("FeatureEditor.field.feature_groups.help")}
                  />
                </TabPane>
              </TabContent>
            </Form>
          </ModalBody>
          <ModalFooter>
            <SubmitButton
              disabled={this.state.submitting} 
              onClick={this.handleSubmit}>
              {((this.props.type === "edit") && (
                <>
                  {t("FeatureEditor.button.update")}
                </>
              )) || ((this.props.type === "create") && (
                <>
                  {t("FeatureEditor.button.create")}
                </>
              ))}
            </SubmitButton>
            <Button color="secondary" onClick={this.closeModal} disabled={this.state.submitting}>
              {t("FeatureEditor.button.cancel")}
            </Button>
            {(this.props.type === "edit") && (
              <Button color="danger" onClick={this.handleDelete} disabled={this.state.submitting}>
                {t("FeatureEditor.button.delete")}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}


export default withTranslation()(FeatureEditor);
