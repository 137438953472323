import React, { Component } from 'react';
import { Card, CardBody, CardHeader
 } from 'reactstrap';
import Feature from '../backend/Feature';
import AssistantFeature from './AssistantFeature';
import FeatureEditor from './FeatureEditor';
import { withTranslation } from 'react-i18next';

import { globalApp } from "./App";


class AssistantFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      features: null,
    };
  }

  // Called when component mounts
  componentDidMount = async () => {
    this.refresh();
  }

  // Called when component updates
  componentDidUpdate = async (prevProps) => {
    if (this.props.featureGroup.uuid !== prevProps.featureGroup.uuid) {
      this.refresh();
    }
  }
  
  refresh = async () => {
    // console.log("refresh()");
    this.setState({loading: true});
    if (this.props.featureGroup === "ALL")
      await Feature.loadAll();
    else
      await Feature.loadAll(this.props.featureGroup);
    const features = Feature.getAll();
    this.setState({loading: false, features: features});
  }

  render() {
    const features = this.state.features;
    const featureGroup = this.props.featureGroup;
    const isPowerUser = globalApp.state.userAccount.isPowerUser();
    const t = this.props.t;

    return !this.state.loading && (
      <Card className="head-main">
        <CardHeader className="head text-white bg-secondary d-flex justify-content-between">
          <span>
            {t("AssistantFeatures.pane.title")}
            {
              ((featureGroup === "ALL") && " - " + t("AssistantFeatures.pane.all"))
              || (featureGroup && `- ${featureGroup.data.title}`)
            }
          </span>
          {isPowerUser && (
            <FeatureEditor 
              type="create" 
              onChanged={this.refresh} 
            />
          )}
        </CardHeader>
        <CardBody className="main main-scroll">
          {Array.from(features).map(([uuid, feature]) => (
            <AssistantFeature 
              inputText={this.props.inputText} 
              feature={feature} 
              key={uuid} 
              index={uuid} 
              onChanged={this.refresh}
            />
          ))}
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation()(AssistantFeatures);
